import * as React from "react";
import {
  Tooltip,
  Avatar,
  Stack,
  Menu,
  MenuItem,
  Divider,
  Button,
  Box,
  CircularProgress,
  IconButton,
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { useStore } from "../../store/storeContext";
import useButtonController from "../../controllers/ButtonController";
import "./button.css";
const LoginButton = () => {
  const { signIn, b2cLoading, userDataCosmos } = useStore();

  const {
    open,
    anchorEl,
    handleOpenSquare,
    handleClose,
    handleSingOut,
    creatingUser,
    width,
    stringAvatar,
  } = useButtonController();

  return (
    <>
      {creatingUser ? (
        <> </>
      ) : (
        <section className="LoginReact-section-container">
          {userDataCosmos.name !== "" && !b2cLoading ? (
            <>
              <Tooltip title="Account settings">
                <IconButton
                  onClick={handleOpenSquare}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  {userDataCosmos.email[0].includes("@snecorp") ? (
                    <Avatar
                      src={process.env.REACT_APP_IMG_URL+"app-bar-images/supra_logo.png"}
                      className="LoginReact-avatar-icon"
                    ></Avatar>
                  ) : (
                    <Avatar {...stringAvatar(userDataCosmos.name)} />
                  )}
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    borderRadius: "30px",
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&::before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: "25%",
                      width: 8,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "center", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem className="LoginReact-menu-item-container">
                  {userDataCosmos.type === "supra" ? (
                    <Avatar
                      src={process.env.REACT_APP_IMG_URL+"app-bar-images/supra_logo.png"}
                      className="LoginReact-avatar-icon"
                    ></Avatar>
                  ) : (
                    <Avatar {...stringAvatar(userDataCosmos.name)} />
                  )}
                </MenuItem>
                <MenuItem>
                  <h5 className="LoginReact-menu-email-text">
                    {userDataCosmos.name}
                  </h5>
                </MenuItem>

                <MenuItem className="LoginReact-menu-item-container">
                  {userDataCosmos.email[0]}
                </MenuItem>
                <Divider />

                <MenuItem
                  onClick={(e) => handleSingOut()}
                  className="LoginReact-menu-item-container"
                >
                  Logout
                </MenuItem>
              </Menu>
            </>
          ) : (
            <>
              {!b2cLoading && userDataCosmos.name === "" ? (
                <>
                  {width > 900 ? (
                    <Tooltip title="Sign In">
                      <Button
                        onClick={async () => {
                          await signIn();
                        }}
                        size="small"
                        className="LoginReact-large-rounded-button"
                      >
                        Sign In
                      </Button>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Sign In">
                      <IconButton
                        onClick={async () => {
                          await signIn();
                        }}
                        className="LoginReact-sign-in-rounded-button"
                      >
                        <LoginIcon className="LoginReact-sign-in-icon-button" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}

          <>
            {b2cLoading ? (
              <div>
                <div className="LoginReact-circular-progress-container">
                  <Box className="LoginReact-circular-progress-box">
                    <CircularProgress
                      thickness={7}
                      className="LoginReact-circular-progress"
                    />
                  </Box>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        </section>
      )}
    </>
  );
};
export default LoginButton;
